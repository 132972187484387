<template>
	<div class="Security">
		<!-- 抬头标题 -->
		<div class="title-hang">
			<div class="fl-t">
				<dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
			</div>
			<div class="zhuangshizb fl-t"></div>
			<div class="zhuangshizj fl-t">{{projectData.project}}_安全设置</div>
			<div class="zhuangshiyb fl-t"></div>
			<div class="fr-t">
				<div class="fl-t">
					<dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
				</div>
			</div>
		</div>
		<!-- 第一个表格 -->
		<div class="table first_table flex">
			<div class="table1 fl">
				<div class="first_main">安全<br />生产<br />阀门<br />设置</div>
			</div>
			<div class="table2 fl">
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">主给水阀门上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCGEISHUI.SWAOH1,
                  '主给水阀门上限',
                  'SWAOH1',
                  'MCGEISHUI'
                )
              ">
							{{ infoList.MCGEISHUI.SWAOH1 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">主给水阀门下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCGEISHUI.SWAOL1,
                  '主给水阀门下限',
                  'SWAOL1',
                  'MCGEISHUI'
                )
              ">
							{{ infoList.MCGEISHUI.SWAOL1 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">1#送风变频上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCSONGFENG.SFBPAOH1,
                  '1#送风变频上限',
                  'SFBPAOH1',
                  'MCSONGFENG'
                )
              ">
							{{ infoList.MCSONGFENG.SFBPAOH1 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">1#送风变频下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCSONGFENG.SFBPAOL1,
                  '1#送风变频下限',
                  'SFBPAOL1',
                  'MCSONGFENG'
                )
              ">
							{{ infoList.MCSONGFENG.SFBPAOL1 }}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">副给水阀门上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCGEISHUI.SWAOH2,
                  '副给水阀门上限',
                  'SWAOH2',
                  'MCGEISHUI'
                )
              ">
							{{ infoList.MCGEISHUI.SWAOH2 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">副给水阀门下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCGEISHUI.SWAOL2,
                  '副给水阀门下限',
                  'SWAOL2',
                  'MCGEISHUI'
                )
              ">
							{{ infoList.MCGEISHUI.SWAOL2 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">2#送风变频上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCSONGFENG.SFBPAOH2,
                  '2#送风变频上限',
                  'SFBPAOH2',
                  'MCSONGFENG'
                )
              ">
							{{ infoList.MCSONGFENG.SFBPAOH2 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">2#送风变频下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCSONGFENG.SFBPAOL2,
                  '2#送风变频下限',
                  'SFBPAOL2',
                  'MCSONGFENG'
                )
              ">
							{{ infoList.MCSONGFENG.SFBPAOL2 }}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">1#给水变频上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCGEISHUI.SSBPAOH1,
                  '1#给水变频上限',
                  'SSBPAOH1',
                  'MCGEISHUI'
                )
              ">
							{{ infoList.MCGEISHUI.SSBPAOH1 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">1#给水变频下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCGEISHUI.SSBPAOL1,
                  '1#给水变频下限',
                  'SSBPAOL1',
                  'MCGEISHUI'
                )
              ">
							{{ infoList.MCGEISHUI.SSBPAOL1 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">1#送风挡板上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCSONGFENG.SFDBAOH1,
                  '1#送风挡板上限',
                  'SFDBAOH1',
                  'MCSONGFENG'
                )
              ">
							{{ infoList.MCSONGFENG.SFDBAOH1 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">1#送风挡板下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCSONGFENG.SFDBAOL1,
                  '1#送风挡板下限',
                  'SFDBAOL1',
                  'MCSONGFENG'
                )
              ">
							{{ infoList.MCSONGFENG.SFDBAOL1 }}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">2#给水变频上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCGEISHUI.SSBPAOH2,
                  '2#给水变频上限',
                  'SSBPAOH2',
                  'MCGEISHUI'
                )
              ">
							{{ infoList.MCGEISHUI.SSBPAOH2 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">2#给水变频下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCGEISHUI.SSBPAOL2,
                  '2#给水变频下限',
                  'SSBPAOL2',
                  'MCGEISHUI'
                )
              ">
							{{ infoList.MCGEISHUI.SSBPAOL2 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">2#送风挡板上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCSONGFENG.SFDBAOH2,
                  '2#送风挡板上限',
                  'SFDBAOH2',
                  'MCSONGFENG'
                )
              ">
							{{ infoList.MCSONGFENG.SFDBAOH2 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">2#送风挡板下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCSONGFENG.SFDBAOL2,
                  '2#送风挡板下限',
                  'SFDBAOL2',
                  'MCSONGFENG'
                )
              ">
							{{ infoList.MCSONGFENG.SFDBAOL2 }}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">一甲减温阀门上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCQIWEN.QWAOH1,
                  '一甲减温阀门上限',
                  'QWAOH1',
                  'MCQIWEN'
                )
              ">
							{{ infoList.MCQIWEN.QWAOH1 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">一甲减温阀门下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCQIWEN.QWAOL1,
                  '一甲减温阀门下限',
                  'QWAOL1',
                  'MCQIWEN'
                )
              ">
							{{ infoList.MCQIWEN.QWAOL1 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">1#引风变频上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCYINFENG.YFBPAOH1,
                  '1#引风变频上限',
                  'YFBPAOH1',
                  'MCYINFENG'
                )
              ">
							{{ infoList.MCYINFENG.YFBPAOH1 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">1#引风变频下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCYINFENG.YFBPAOL1,
                  '1#引风变频下限',
                  'YFBPAOL1',
                  'MCYINFENG'
                )
              ">
							{{ infoList.MCYINFENG.YFBPAOL1 }}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">一乙减温阀门上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCQIWEN.QWAOH2,
                  '一乙减温阀门上限',
                  'QWAOH2',
                  'MCQIWEN'
                )
              ">
							{{ infoList.MCQIWEN.QWAOH2 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">一乙减温阀门下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCQIWEN.QWAOL2,
                  '一乙减温阀门下限',
                  'QWAOL2',
                  'MCQIWEN'
                )
              ">
							{{ infoList.MCQIWEN.QWAOL2 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">2#引风变频上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCYINFENG.YFBPAOH2,
                  '2#引风变频上限',
                  'YFBPAOH2',
                  'MCYINFENG'
                )
              ">
							{{ infoList.MCYINFENG.YFBPAOH2 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">2#引风变频下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCYINFENG.YFBPAOL2,
                  '2#引风变频下限',
                  'YFBPAOL2',
                  'MCYINFENG'
                )
              ">
							{{ infoList.MCYINFENG.YFBPAOL2 }}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">二甲减温阀门上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCQIWEN.QWAOH3,
                  '二甲减温阀门上限',
                  'QWAOH3',
                  'MCQIWEN'
                )
              ">
							{{ infoList.MCQIWEN.QWAOH3 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">二甲减温阀门下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCQIWEN.QWAOL3,
                  '二甲减温阀门下限',
                  'QWAOL3',
                  'MCQIWEN'
                )
              ">
							{{ infoList.MCQIWEN.QWAOL3 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">1#引风挡板上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCYINFENG.YFDBAOH1,
                  '1#引风挡板上限',
                  'YFDBAOH1',
                  'MCYINFENG'
                )
              ">
							{{ infoList.MCYINFENG.YFDBAOH1 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">1#引风挡板下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCYINFENG.YFDBAOL1,
                  '1#引风挡板下限',
                  'YFDBAOL1',
                  'MCYINFENG'
                )
              ">
							{{ infoList.MCYINFENG.YFDBAOL1 }}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">二乙减温阀门上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCQIWEN.QWAOH4,
                  '二乙减温阀门上限',
                  'QWAOH4',
                  'MCQIWEN'
                )
              ">
							{{ infoList.MCQIWEN.QWAOH4 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">二乙减温阀门下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCQIWEN.QWAOL4,
                  '二乙减温阀门下限',
                  'QWAOL4',
                  'MCQIWEN'
                )
              ">
							{{ infoList.MCQIWEN.QWAOL4 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">2#引风挡板上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCYINFENG.YFDBAOH2,
                  '2#引风挡板上限',
                  'YFDBAOH2',
                  'MCYINFENG'
                )
              ">
							{{ infoList.MCYINFENG.YFDBAOH2 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">2#引风挡板下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCYINFENG.YFDBAOL2,
                  '2#引风挡板下限',
                  'YFDBAOL2',
                  'MCYINFENG'
                )
              ">
							{{ infoList.MCYINFENG.YFDBAOL2 }}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">上层高煤上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCRANQI.GLAOH3,
                  '上层高煤上限',
                  'GLAOH3',
                  'MCRANQI'
                )
              ">
							{{ infoList.MCRANQI.GLAOH3 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">上层高煤下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCRANQI.GLAOL3,
                  '上层高煤下限',
                  'GLAOL3',
                  'MCRANQI'
                )
              ">
							{{ infoList.MCRANQI.GLAOL3 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">上层空气上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCSONGFENG.SFCCAOH3,
                  '上层空气上限',
                  'SFCCAOH3',
                  'MCSONGFENG'
                )
              ">
							{{ infoList.MCSONGFENG.SFCCAOH3 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">上层空气下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCSONGFENG.SFCCAOL3,
                  '上层空气下限',
                  'SFCCAOL3',
                  'MCSONGFENG'
                )
              ">
							{{ infoList.MCSONGFENG.SFCCAOL3 }}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">中层高煤上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCRANQI.GLAOH2,
                  '中层高煤上限',
                  'GLAOH2',
                  'MCRANQI'
                )
              ">
							{{ infoList.MCRANQI.GLAOH2 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">中层高煤下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCRANQI.GLAOL2,
                  '中层高煤下限',
                  'GLAOL2',
                  'MCRANQI'
                )
              ">
							{{ infoList.MCRANQI.GLAOL2 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">中层空气上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCSONGFENG.SFCCAOH2,
                  '中层空气上限',
                  'SFCCAOH2',
                  'MCSONGFENG'
                )
              ">
							{{ infoList.MCSONGFENG.SFCCAOH2 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">中层空气下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCSONGFENG.SFCCAOL2,
                  '中层空气下限',
                  'SFCCAOL2',
                  'MCSONGFENG'
                )
              ">
							{{ infoList.MCSONGFENG.SFCCAOL2 }}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">下层高煤上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCRANQI.GLAOH1,
                  '下层高煤上限',
                  'GLAOH1',
                  'MCRANQI'
                )
              ">
							{{ infoList.MCRANQI.GLAOH1 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">下层高煤下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCRANQI.GLAOL1,
                  '下层高煤下限',
                  'GLAOL1',
                  'MCRANQI'
                )
              ">
							{{ infoList.MCRANQI.GLAOL1 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">下层空气上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCSONGFENG.SFCCAOH1,
                  '下层空气上限',
                  'SFCCAOH1',
                  'MCSONGFENG'
                )
              ">
							{{ infoList.MCSONGFENG.SFCCAOH1 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">下层空气下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCSONGFENG.SFCCAOL1,
                  '下层空气下限',
                  'SFCCAOL1',
                  'MCSONGFENG'
                )
              ">
							{{ infoList.MCSONGFENG.SFCCAOL1 }}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">脱硝阀门上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCSO2NOX.NSAOH,
                  '脱硝阀门上限',
                  'NSAOH',
                  'MCSO2NOX'
                )
              ">
							{{ infoList.MCSO2NOX.NSAOH }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">脱硝阀门下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCSO2NOX.NSAOL,
                  '脱硝阀门下限',
                  'NSAOL',
                  'MCSO2NOX'
                )
              ">
							{{ infoList.MCSO2NOX.NSAOL }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">脱硝阀门上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCSO2NOX.SO2AOH,
                  '脱硝阀门上限',
                  'SO2AOH',
                  'MCSO2NOX'
                )
              ">
							{{ infoList.MCSO2NOX.SO2AOH }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">脱硝阀门下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCSO2NOX.SO2AOL,
                  '脱硝阀门下限',
                  'SO2AOL',
                  'MCSO2NOX'
                )
              ">
							{{ infoList.MCSO2NOX.SO2AOL }}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">除盐水阀门上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCSO2NOX.CYSAOH,
                  '除盐水阀门上限',
                  'CYSAOH',
                  'MCSO2NOX'
                )
              ">
							{{ infoList.MCSO2NOX.CYSAOH }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">除盐水阀门下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCSO2NOX.CYSAOL,
                  '除盐水阀门下限',
                  'CYSAOL',
                  'MCSO2NOX'
                )
              ">
							{{ infoList.MCSO2NOX.CYSAOL }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl"></div>
						<div class="first_line fl"></div>
					</div>
					<div class="flex">
						<div class="first_line1 fl"></div>
						<div class="first_line fl"></div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">1#送风机电流上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCSONGFENG.ISFJ_H1,
                  '1#送风机电流上限',
                  'ISFJ_H1',
                  'MCSONGFENG'
                )
              ">
							{{ infoList.MCSONGFENG.ISFJ_H1 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">2#送风机电流下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCSONGFENG.ISFJ_H2,
                  '2#送风机电流下限',
                  'ISFJ_H2',
                  'MCSONGFENG'
                )
              ">
							{{ infoList.MCSONGFENG.ISFJ_H2 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">1#引风机电流下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCYINFENG.IYFJ_H1,
                  '1#引风机电流下限',
                  'IYFJ_H1',
                  'MCYINFENG'
                )
              ">
							{{ infoList.MCYINFENG.IYFJ_H1 }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">2#引风机电流上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCYINFENG.IYFJ_H2,
                  '2#引风机电流上限',
                  'IYFJ_H2',
                  'MCYINFENG'
                )
              ">
							{{ infoList.MCYINFENG.IYFJ_H2 }}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">再热器阀门上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCZAIRE.ZRAOH,
                  '再热器阀门上限',
                  'ZRAOH',
                  'MCZAIRE'
                )
              ">
							{{ infoList.MCZAIRE.ZRAOH }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">再热器阀门下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.MCZAIRE.ZRAOL,
                  '再热器阀门下限',
                  'ZRAOL',
                  'MCZAIRE'
                )
              ">
							{{ infoList.MCZAIRE.ZRAOL }}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl"></div>
						<div class="first_line fl"></div>
					</div>
					<div class="flex">
						<div class="first_line1 fl"></div>
						<div class="first_line fl"></div>
					</div>
				</div>
			</div>
		</div>
		<!-- 第二个表格 -->
		<div class="table second_table flex">
			<div class="table1 fl">
				<div class="second_main">控制点<br />限幅</div>
			</div>
			<div class="table2 fl">
				<div class="table_line flex">
					<div class="flex">
						<div class="second_line1 fl">水位控制点上限</div>
						<div class="second_line fl" @click="
                toIpt(
                  infoList.MCSPCL.SWSP_H,
                  '水位控制点上限',
                  'SWSP_H',
                  'MCSPCL'
                )
              ">
							{{ infoList.MCSPCL.SWSP_H }}
						</div>
					</div>
					<div class="flex">
						<div class="second_line1 fl">水位控制点下限</div>
						<div class="second_line fl" @click="
                toIpt(
                  infoList.MCSPCL.SWSP_L,
                  '水位控制点下限',
                  'SWSP_L',
                  'MCSPCL'
                )
              ">
							{{ infoList.MCSPCL.SWSP_L }}
						</div>
					</div>
					<div class="flex">
						<div class="second_line1 fl">汽温控制点上限</div>
						<div class="second_line fl" @click="
                toIpt(
                  infoList.MCSPCL.QWSP_H,
                  '汽温控制点上限',
                  'QWSP_H',
                  'MCSPCL'
                )
              ">
							{{ infoList.MCSPCL.QWSP_H }}
						</div>
					</div>
					<div class="flex">
						<div class="second_line1 fl">汽温控制点下限</div>
						<div class="second_line fl" @click="
                toIpt(
                  infoList.MCSPCL.QWSP_L,
                  '汽温控制点下限',
                  'QWSP_L',
                  'MCSPCL'
                )
              ">
							{{ infoList.MCSPCL.QWSP_L }}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="second_line1 fl">负压控制点上限</div>
						<div class="second_line fl" @click="
                toIpt(
                  infoList.MCSPCL.FYSP_H,
                  '负压控制点上限',
                  'FYSP_H',
                  'MCSPCL'
                )
              ">
							{{ infoList.MCSPCL.FYSP_H }}
						</div>
					</div>
					<div class="flex">
						<div class="second_line1 fl">负压控制点下限</div>
						<div class="second_line fl" @click="
                toIpt(
                  infoList.MCSPCL.FYSP_L,
                  '负压控制点下限',
                  'FYSP_L',
                  'MCSPCL'
                )
              ">
							{{ infoList.MCSPCL.FYSP_L }}
						</div>
					</div>
					<div class="flex">
						<div class="second_line1 fl">氧量控制点上限</div>
						<div class="second_line fl" @click="
                toIpt(
                  infoList.MCSONGFENG.O2SPH,
                  '氧量控制点上限',
                  'O2SPH',
                  'MCSONGFENG'
                )
              ">
							{{ infoList.MCSONGFENG.O2SPH }}
						</div>
					</div>
					<div class="flex">
						<div class="second_line1 fl">氧量控制点下限</div>
						<div class="second_line fl" @click="
                toIpt(
                  infoList.MCSONGFENG.O2SPL,
                  '氧量控制点下限',
                  'O2SPL',
                  'MCSONGFENG'
                )
              ">
							{{ infoList.MCSONGFENG.O2SPL }}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="second_line1 fl">汽压控制点上限</div>
						<div class="second_line fl" @click="
                toIpt(
                  infoList.MCRANQI.QYPSPH,
                  '汽压控制点上限',
                  'QYPSPH',
                  'MCRANQI'
                )
              ">
							{{ infoList.MCRANQI.QYPSPH }}
						</div>
					</div>
					<div class="flex">
						<div class="second_line1 fl">汽压控制点下限</div>
						<div class="second_line fl" @click="
                toIpt(
                  infoList.MCRANQI.QYPSPL,
                  '汽压控制点下限',
                  'QYPSPL',
                  'MCRANQI'
                )
              ">
							{{ infoList.MCRANQI.QYPSPL }}
						</div>
					</div>
					<div class="flex">
						<div class="second_line1 fl">发电量控制点上限</div>
						<div class="second_line fl" @click="
                toIpt(
                  infoList.MCRANQI.FDLSPH,
                  '发电量控制点上限',
                  'FDLSPH',
                  'MCRANQI'
                )
              ">
							{{ infoList.MCRANQI.FDLSPH }}
						</div>
					</div>
					<div class="flex">
						<div class="second_line1 fl">发电量控制点下限</div>
						<div class="second_line fl" @click="
                toIpt(
                  infoList.MCRANQI.FDLSPL,
                  '发电量控制点下限',
                  'FDLSPL',
                  'MCRANQI'
                )
              ">
							{{ infoList.MCRANQI.FDLSPL }}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="second_line1 fl"></div>
						<div class="second_line fl"></div>
					</div>
					<div class="flex">
						<div class="second_line1 fl"></div>
						<div class="second_line fl"></div>
					</div>
					<div class="flex">
						<div class="second_line1 fl">高煤压力控制点上限</div>
						<div class="second_line fl" @click="
                toIpt(
                  infoList.MCRANQI.GLPSPH,
                  '高煤压力控制点上限',
                  'GLPSPH',
                  'MCRANQI'
                )
              ">
							{{ infoList.MCRANQI.GLPSPH }}
						</div>
					</div>
					<div class="flex">
						<div class="second_line1 fl">高煤压力控制点下限</div>
						<div class="second_line fl" @click="
                toIpt(
                  infoList.MCRANQI.GLPSPL,
                  '高煤压力控制点下限',
                  'GLPSPL',
                  'MCRANQI'
                )
              ">
							{{ infoList.MCRANQI.GLPSPL }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 第三个表格 -->
		<div class="table third_table flex">
			<div class="table1 fl">
				<div class="third_main">安全<br />生产<br />报警<br />设置</div>
			</div>
			<div class="table2 fl">
				<div class="table_line flex">
					<div class="flex">
						<div class="third_line2 flex">
							<div @click="
                  toIpt(
                    infoList.MCALARM.SWYCEN,
                    '汽包液位上限',
                    'SWYCEN',
                    'MCALARM'
                  )
                ">
								<el-switch v-model="infoList.MCALARM.SWYCEN" active-color="#29DFAE"
									inactive-color="red">
								</el-switch>
							</div>
							<div class="third_line3">汽包液位上限</div>
						</div>
						<div class="third_line" @click="
                toIpt(
                  infoList.MCALARM.SWYC_H,
                  '汽包液位上限',
                  'SWYC_H',
                  'MCALARM'
                )
              ">
							{{ infoList.MCALARM.SWYC_H }}
						</div>
					</div>
					<div class="flex">
						<div class="third_line1">汽包液位下限</div>
						<div class="third_line" @click="
                toIpt(
                  infoList.MCALARM.SWYC_L,
                  '汽包液位下限',
                  'SWYC_L',
                  'MCALARM'
                )
              ">
							{{ infoList.MCALARM.SWYC_L }}
						</div>
					</div>
					<div class="flex">
						<div class="third_line2 flex">
							<div @click="
                  toIpt(
                    infoList.MCALARM.QYYCEN,
                    '主汽压力上限',
                    'QYYCEN',
                    'MCALARM'
                  )
                ">
								<el-switch v-model="infoList.MCALARM.QYYCEN" active-color="#29DFAE"
									inactive-color="red">
								</el-switch>
							</div>
							<div class="third_line3">主汽压力上限</div>
						</div>
						<div class="third_line" @click="
                toIpt(
                  infoList.MCALARM.QYYC_H,
                  '主汽压力上限',
                  'QYYC_H',
                  'MCALARM'
                )
              ">
							{{ infoList.MCALARM.QYYC_H }}
						</div>
					</div>
					<div class="flex">
						<div class="third_line1">主汽压力下限</div>
						<div class="third_line" @click="
                toIpt(
                  infoList.MCALARM.QYYC_L,
                  '主汽压力下限',
                  'QYYC_L',
                  'MCALARM'
                )
              ">
							{{ infoList.MCALARM.QYYC_L }}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="third_line2 flex">
							<div @click="
                  toIpt(
                    infoList.MCALARM.QWYCEN,
                    '主汽温度上限',
                    'QWYCEN',
                    'MCALARM'
                  )
                ">
								<el-switch v-model="infoList.MCALARM.QWYCEN" active-color="#29DFAE"
									inactive-color="red">
								</el-switch>
							</div>
							<div class="third_line3">主汽温度上限</div>
						</div>
						<div class="third_line fl" @click="
                toIpt(
                  infoList.MCALARM.QWYC_H,
                  '主汽温度上限',
                  'QWYC_H',
                  'MCALARM'
                )
              ">
							{{ infoList.MCALARM.QWYC_H }}
						</div>
					</div>
					<div class="flex">
						<div class="third_line1 fl">主汽温度下限</div>
						<div class="third_line fl" @click="
                toIpt(
                  infoList.MCALARM.QWYC_L,
                  '主汽温度下限',
                  'QWYC_L',
                  'MCALARM'
                )
              ">
							{{ infoList.MCALARM.QWYC_L }}
						</div>
					</div>
					<div class="flex">
						<div class="third_line2 flex">
							<div @click="
                  toIpt(
                    infoList.MCALARM.GLPYCEN,
                    '高煤压力上限',
                    'GLPYCEN',
                    'MCALARM'
                  )
                ">
								<el-switch v-model="infoList.MCALARM.GLPYCEN" active-color="#29DFAE"
									inactive-color="red">
								</el-switch>
							</div>
							<div class="third_line3">高煤压力上限</div>
						</div>
						<div class="third_line fl" @click="
                toIpt(
                  infoList.MCALARM.GLPYC_H,
                  '高煤压力上限',
                  'GLPYC_H',
                  'MCALARM'
                )
              ">
							{{ infoList.MCALARM.GLPYC_H }}
						</div>
					</div>
					<div class="flex">
						<div class="third_line1 fl">高煤压力下限</div>
						<div class="third_line fl" @click="
                toIpt(
                  infoList.MCALARM.GLPYC_L,
                  '高煤压力下限',
                  'GLPYC_L',
                  'MCALARM'
                )
              ">
							{{ infoList.MCALARM.GLPYC_L }}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="third_line2 flex">
							<div @click="
                  toIpt(
                    infoList.MCALARM.FYYCEN,
                    '炉膛负压上限',
                    'FYYCEN',
                    'MCALARM'
                  )
                ">
								<el-switch v-model="infoList.MCALARM.FYYCEN" active-color="#29DFAE"
									inactive-color="red">
								</el-switch>
							</div>
							<div class="third_line3">炉膛负压上限</div>
						</div>
						<div class="third_line fl" @click="
                toIpt(
                  infoList.MCALARM.FYYC_H,
                  '炉膛负压上限',
                  'FYYC_H',
                  'MCALARM'
                )
              ">
							{{ infoList.MCALARM.FYYC_H }}
						</div>
					</div>
					<div class="flex">
						<div class="third_line1 fl">炉膛负压下限</div>
						<div class="third_line fl" @click="
                toIpt(
                  infoList.MCALARM.FYYC_L,
                  '炉膛负压下限',
                  'FYYC_L',
                  'MCALARM'
                )
              ">
							{{ infoList.MCALARM.FYYC_L }}
						</div>
					</div>
					<div class="flex">
						<div class="third_line2 flex">
							<div @click="
                  toIpt(
                    infoList.MCALARM.YHZEN1,
                    '优化站异常报警',
                    'YHZEN1',
                    'MCALARM'
                  )
                ">
								<el-switch v-model="infoList.MCALARM.YHZEN1" active-color="#29DFAE"
									inactive-color="red">
								</el-switch>
							</div>
							<div class="third_line3">优化站异常报警</div>
						</div>
						<div class="third_line fl"></div>
					</div>
					<div class="flex">
						<div class="third_line1 fl"></div>
						<div class="third_line fl"></div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="third_line2 flex">
							<div @click="
                  toIpt(
                    infoList.MCALARM.O2YCEN,
                    '烟气氧量上限',
                    'O2YCEN',
                    'MCALARM'
                  )
                ">
								<el-switch v-model="infoList.MCALARM.O2YCEN" active-color="#29DFAE"
									inactive-color="red">
								</el-switch>
							</div>
							<div class="third_line3">烟气氧量上限</div>
						</div>
						<div class="third_line fl" @click="
                toIpt(
                  infoList.MCALARM.O2YC_H,
                  '烟气氧量上限',
                  'O2YC_H',
                  'MCALARM'
                )
              ">
							{{ infoList.MCALARM.O2YC_H }}
						</div>
					</div>
					<div class="flex">
						<div class="third_line1 fl">烟气氧量下限</div>
						<div class="third_line fl" @click="
                toIpt(
                  infoList.MCALARM.O2YC_L,
                  '烟气氧量下限',
                  'O2YC_L',
                  'MCALARM'
                )
              ">
							{{ infoList.MCALARM.O2YC_L }}
						</div>
					</div>
					<div class="flex">
						<div class="third_line1 fl"></div>
						<div class="third_line fl"></div>
					</div>
					<div class="flex">
						<div class="third_line1 fl"></div>
						<div class="third_line fl"></div>
					</div>
				</div>
			</div>
		</div>
		<inputVal ref="inputVal" @getTreeData="DataJson(arguments)"></inputVal>
		<Historical v-if="isHshow" @sendStatus="isHshow = false" :historyname="historyname" :node="node"
			:Lkname="Lkname" :infoList="infoList"></Historical>
		<RqCommonParameters v-if="flag == 4 ? (isComShow = true) : (isComShow = false)" @sendStatus="isClose"
			:infoList="infoList"></RqCommonParameters>
		<RqSoftMeasurement v-if="flag == 6 ? (isComShow = true) : (isComShow = false)" @sendStatus="isClose"
			:infoList="infoList"></RqSoftMeasurement>
		<RqOnlineService v-if="flag == 7 ? (isComShow = true) : (isComShow = false)" @sendStatus="isClose"
			:infoList="infoList"></RqOnlineService>
	</div>
</template>

<script>
	import inputVal from "@/components/inputVal.vue"; //输入框组件
	import Historical from "@/components/Historical.vue"; //历史趋势
	import RqCommonParameters from "@/views/RqBoiler/RqCommonParameters/index.vue"; //常用参数组件
	import RqSoftMeasurement from "@/views/RqBoiler/RqSoftNumber/index.vue"; //软测量数统
	import RqOnlineService from "@/views/RqBoiler/RqOnlineService/index.vue"; //客服
	export default {
		name: "Param11",
		props: {
			infoList: {
				type: Object,
				default: {}, // 默认值
			},
		},
		components: {
			inputVal,
			Historical,
			RqCommonParameters,
			RqSoftMeasurement,
			RqOnlineService,
		},
		data: () => {
			return {
				flag: 2,
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				isHshow: false,
				isMshow: false,
				isRshow: false,
				historyname: "",
				node: "",
				Lkname: "",
				isIndex: "",
				Firstcontrolname: "",
				Firstcontroltitname: "",
				Firstcontrolnode: "",
				isFshow: false,
				Rsfname: "",
				Rsfnode: "",
				Rsftitname: "",
				projectData: "",
				grouptime: null,
				spotArr: [],
				authInfo: [],
				UserInfo: [],
			};
		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"));
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ?
				JSON.parse(localStorage.getItem("spotArr")) :
				[];
			this.authInfo = JSON.parse(localStorage.getItem("autharr"));
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
			this.$bus.$on("sendMsg", (msg) => {
				// 兄弟组件发送来的消息
				this.flag = msg;
			});
		},
		methods: {
			DataJson(data) {
				let strNode = data[0];
				let strMark = data[1];
				this.$nextTick(() => {
					this.infoList[strNode][strMark] = data[2];
				});
			},
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0];
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error("暂无调试权限");
					}
				} else {
					let pathname = "";
					switch (key) {
						case 1:
							pathname = "firstcontol";
							break;
						case 2:
							pathname = "rqkfirstcontol";
							break;
						case 3:
							pathname = "RqRfirstcontol";
							break;
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname,
						},
					});
					window.open(newurl.href, "_blank");
				}
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name,
				};
				this.spotArr.push(spojobj);
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll();
							map.set(item.name, item);
							this.$message.success("已添加");
						} else {
							this.$message.closeAll();
							this.$message.error("已添加到变量池里，切勿重复添加");
						}
					}

					return [...map.values()];
				};
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
			},
			isshowfase() {
				this.isComShow = false;
			},
			closeCompon() {
				this.$emit("sendStatus", "Param03", false);
			},
			isClose(val, val1) {
				switch (val) {
					case "Historical":
						return (this.isHshow = val1);
					case "RqOnlineService":
					case this.flag == 7:
						return (this.flag = 2), this.$bus.$emit("footersendMsg", 2);
					case "RqSoftNumber":
					case this.flag == 6:
						return (this.flag = 2), this.$bus.$emit("footersendMsg", 2);
					case "RqCommonParameters":
					case this.flag == 4:
						return (this.flag = 2), this.$bus.$emit("footersendMsg", 2);
				}
			},
			// 打开下置输入窗口
			toIpt(data, name, historyname, node, type, status) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0];
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error("暂无调试权限");
					}
				} else {
					this.$refs.inputVal.open(data, name, historyname, node, type, status);
				}
			},
			toCompon(key, name, name2, name3, titname) {
				this.isIndex = key;
				this.historyname = name;
				this.node = name2;
				this.Lkname = name3;
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							console.log(1);
							return (
								(this.isMshow = true),
								(this.Manualname = name),
								(this.Manualnode = name2),
								(this.ManualAname = name3),
								(this.Manualtitname = titname)
							);
						case 1:
							return (
								(this.isFshow = true),
								(this.Firstcontrolname = name),
								(this.Firstcontrolnode = name2),
								(this.Firstcontroltitname = titname)
							);
						case 2:
							return (this.isHshow = true);
						case 4:
							return (
								(this.isRshow = true),
								(this.Rsfname = name),
								(this.Rsfnode = name2),
								(this.Rsftitname = titname)
							);
					}
				}, 300);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.fl-t {
		float: left;
	}

	.fr-t {
		float: right;
	}

	.title-hang {
		height: 5vh;
		width: 96vw;
		font-family: MicrosoftYaHei;
		font-size: 1vw;
		font-weight: normal;
		font-stretch: normal;
		line-height: 2vh;
		letter-spacing: 0vh;
	}

	.zhuangshizb {
		width: 14vw;
		height: 2vh;
		background-image: url("~@/assets/images/rfl_evaluate06.png");
		background-size: 100% 100%;
		margin-left: 3.5vw;
		margin-top: 1.5vh;
	}

	.zhuangshizj {
		width: 26vw;
		height: 4vh;
		line-height: 4vh;
		margin-top: 1vh;
		text-align: center;
		background-image: url("~@/assets/images/rfl_evaluate05.png");
		background-size: 100% 100%;
		margin-left: 5vw;
		font-family: MicrosoftYaHei;
		font-size: 1.6vw;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0vh;
		color: #0ef7ff;
	}

	.zhuangshiyb {
		width: 14vw;
		height: 2vh;
		background-image: url("~@/assets/images/rfl_evaluate04.png");
		background-size: 100% 100%;
		margin-left: 5vw;
		margin-top: 1.5vh;
	}

	.table {
		width: 95vw;
		margin-left: 1.5vw;
		border: 1px solid #00e4ff;
		box-sizing: border-box;
		margin-top: 1vh;
		font-family: PingFang-SC-Regular;
		font-size: 1vw;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0vh;
		color: #8aeaff;
	}

	.first_table {
		height: 45vh;
	}

	.first_table .table1 {
		width: 4vw;
		height: 45vh;
		border: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;
	}

	.first_table .table1 .first_main {
		width: 4vw;
		height: 19vh;
		font-size: 1.3vw;
		line-height: 6vh;
		margin-top: 11vh;
		text-align: center;
	}

	.first_table .table2 {
		width: 91vw;
		height: 45vh;
		line-height: 3vh;
		border: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;
		text-align: center;
	}

	.first_table .table2 .table_line {
		width: 100%;
		height: 3vh;
		border-bottom: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;

		.flex {
			width: 22.75vw;
		}
	}

	.table .table2 .table_line .first_line1 {
		width: 19vw;
		border-right: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;
	}

	.table .table2 .table_line .first_line {
		width: 3.75vw;
		border-right: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;
		cursor: pointer;
	}

	.first_line:last-child {
		border-right: none;
	}

	.second_table {
		height: 15vh;
	}

	.second_table .table1 {
		width: 4vw;
		border: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;
	}

	.second_table .table1 .second_main {
		width: 4vw;
		font-size: 1.2vw;
		line-height: 3vh;
		margin-top: 4vh;
		text-align: center;
	}

	.second_table .table2 {
		width: 91vw;
		line-height: 3vh;
		border: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;
		text-align: center;
	}

	.second_table .table2 .table_line {
		width: 100%;
		height: 3.7vh;
		border-bottom: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;

		.flex {
			width: 22.75vw;
		}
	}

	.second_table .table2 .table_line .second_line1 {
		width: 19vw;
		border-right: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;
	}

	.second_table .table2 .table_line .second_line {
		width: 3.75vw;
		border-right: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;
		cursor: pointer;
	}

	.second_line:last-child {
		border-right: none;
	}

	.third_table {
		height: 15vh;
	}

	.third_table .table1 {
		width: 4vw;
		border: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;
	}

	.third_table .table1 .third_main {
		width: 4vw;
		font-size: 1.3vw;
		line-height: 3vh;
		margin-top: 1.3vh;
		text-align: center;
	}

	.third_table .table2 {
		width: 91vw;
		line-height: 3vh;
		border: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;
	}

	.third_table .table2 .table_line {
		width: 100%;
		height: 3.7vh;
		border-bottom: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;

		.flex {
			width: 22.75vw;
		}

		position: relative;
	}

	.third_table .table2 .table_line .third_line1 {
		width: 19vw;
		border-right: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;
		text-align: center;
	}

	.third_table .table2 .table_line .third_line2 {
		width: 19vw;
		border-right: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;
		position: relative;
	}

	.third_table .table2 .table_line .third_line2 .third_line3 {
		width: 19vw;
		text-align: center;
		position: absolute;
	}

	.third_table .table2 .table_line .third_line {
		width: 3.75vw;
		border-right: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;
		text-align: center;
		cursor: pointer;
	}

	.third_line:last-child {
		border-right: none;
	}

	.el-switch {
		position: relative;
		margin-top: 0.8vh;
		margin-left: 1vw;
		z-index: 1 !important;
	}

	//  .el-switch.is-disabled{
	//     pacity: 1 !important;
	//  }
</style>
